import { Helmet, Interfaces } from "@life-without-barriers/react-components"
import { Site } from "@life-without-barriers/gatsby-common"
import { graphql } from "gatsby"
import React from "react"
import queryString from "query-string"
import haversine from "haversine"

import { ContactUsLocation, LocationPageParameters } from "../../contentTypes"
import LocationResults from "../../components/contact-us/LocationResults"

interface Props {
  location: { pathname: string; search: string }
  data: {
    site: Site
    locations: {
      edges: Array<{
        node: ContactUsLocation
      }>
    }
  }
  theme: Interfaces.Theme
}

const LocationsPage = ({
  location,
  data: {
    site: { siteMetadata },
    locations
  }
}: Props) => {
  const locationNodes = locations.edges.map((e) => e.node)
  const queryParams = queryString.parse(location.search)
  const searchParams: LocationPageParameters = {
    lat: Number(queryParams.lat),
    lon: Number(queryParams.lon),
    address: queryParams.address ? String(queryParams.address) : "",
    enteredAddress: queryParams.enteredAddress
      ? String(queryParams.enteredAddress)
      : ""
  }
  const nearbyLocations = locationNodes
    .map((nearby) => {
      return {
        ...nearby,
        distance: haversine(nearby.location, searchParams, {
          format: "{lon,lat}"
        })
      }
    })
    .sort((a, b) => a.distance - b.distance)
    .slice(0, 5)
  const currentLocation = nearbyLocations[0]
  const orderedNearby = nearbyLocations.slice(1)
  return (
    <div>
      <Helmet
        title={`Contact us - find a location | ${siteMetadata.title}`}
        description="Contact Life Without Barriers for assistance with disability services, foster care, aged care and other programs."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
      />
      <LocationResults
        searchParams={searchParams}
        orderedNearby={orderedNearby}
        currentLocation={currentLocation}
      />
    </div>
  )
}

export const query = graphql`
  query {
    locations: allContentfulContactUsLocation {
      edges {
        node {
          ...contactUsLocationFragment
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default LocationsPage
